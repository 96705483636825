<template>
  <Layout :pagetitle="`${(formRaffle.id ? 'Editar' : 'Novo')} sorteio`">
    <div class="row">
      <div class="col-lg-12">
        <b-alert
          show
          v-if="pendingRequirements.length > 0"
          variant="danger"
          class="text-uppercase"
        >
          <p v-for="(item, i) in pendingRequirements" :key="i">
            {{ item.message }}
          </p>
        </b-alert>
      </div>
      <div class="col-lg-12">
        <div class="mb-2 text-uppercase" v-if="contest?.status === 'PAYMENT'">
          <div
            class="alert alert-danger fade show"
            role="alert"
            @click="showPaymentRequest"
            style="cursor: pointer;"
          >
            <i class="mdi mdi-block-helper me-2"></i>
            Este sorteio encontra-se pendente de pagamento para publicação
            - Clique aqui para resolver
          </div>
        </div>
          <div class="card">
              <div class="p-4 border-top">
                <form @submit.prevent="submitRaffle" class="was-validated">
                  <div class="row">
                    <div class="mb-3">
                      <label
                        class="form-label"
                        for="rafflestitle"
                      >
                        Título
                      </label>
                      <input
                        v-model="formRaffle.title"
                        id="rafflestitle"
                        name="rafflestitle"
                        type="text"
                        class="form-control"
                        :disabled="disabledInput"
                        :maxlength="titleMaxLength"
                        required
                      />
                      <div class="invalid-tooltip">
                        Informe um nome para o sorteio com no máximo 70 caractéres!
                      </div>
                    </div>
                    <div class="mb-3">
                      <label class="form-label" for="rafflesdescription"
                      >Descrição</label
                      >
                      <textarea
                        v-model="formRaffle.short_description"
                        class="form-control"
                        id="rafflesdescription"
                        placeholder="Descreva quais são as regras do sorteio e outras informações"
                        rows="4"
                        :maxlength="descriptionMaxLength"
                        required
                      ></textarea>
                      <div class="text-end">
                        <div v-if="formRaffle.short_description.length > descriptionMaxLength" class="text-danger">
                          {{ formRaffle.short_description.length }} / {{ descriptionMaxLength }}
                        </div>
                        <div v-else class="text-success">
                          {{ formRaffle.short_description.length }} / {{ descriptionMaxLength }}
                        </div>
                      </div>
                      <div class="invalid-tooltip">
                        Informe uma descrição de no máximo {{ descriptionMaxLength }} caracteres para o sorteio!
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-4">
                      <div class="mb-3">
                        <label class="form-label" for="rafflesprice">
                          R$ Valor
                        </label>
                        <money3
                          id="rafflesprice"
                          class="form-control"
                          v-model="formRaffle.price"
                          v-bind="{
                            prefix: '',
                            precision: 2,
                            thousands: '.',
                            decimal: ','
                          }"
                        />
                        <div class="invalid-tooltip">
                          O preço deve ser maior que R$ 0,10
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="mb-3">
                        <label class="form-label" for="raffleswhatsapp"
                        >WhatsApp para contato</label
                        >
                        <input
                            v-model="formRaffle.whatsapp_number"
                            v-mask="['(##) ####-####', '(##) #####-####']"
                            id="raffleswhatsapp"
                            name="raffleswhatsapp"
                            type="text"
                            class="form-control"
                            required
                        />
                        <div class="invalid-tooltip">
                          Informe um WhatsApp para contato
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="mb-3">
                        <label class="form-label" for="rafflesgroup"
                        >Grupo do sorteio</label>
                        <input
                            v-model="formRaffle.whatsapp_group"
                            id="rafflesgroup"
                            name="rafflesgroup"
                            type="url"
                            class="form-control"
                        />
                        <div class="invalid-tooltip">
                          Informe um grupo para o sorteio
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4" v-if="isLicensedAccount()">
                      <div class="mb-3">
                        <label class="form-label" for="rafflesidentifier"
                        >Identificador</label>
                        <input
                            v-model="formRaffle.identifier"
                            id="rafflesidentifier"
                            name="rafflesidentifier"
                            class="form-control"
                        />
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="mb-3">
                        <label class="form-label" for="rafflesidentifier"
                        >Data do Sorteio</label>
                        <VueDatePicker
                          class="form-control remove-paddings"
                          v-model="formRaffle.contest_date"
                          auto-apply
                          close-on-scroll
                          format="dd/MM/yyyy"
                          :enable-time-picker="false"
                          locale="pt"
                          model-type="yyyy-MM-dd"
                          dark
                          :clearable="false"
                        />
                      </div>
                    </div>
                    <div v-if="formRaffle.id" class="col-sm-4 mt-4 pt-3">
                      A quantidade de números não pode ser alterada ({{ formRaffle.quantity }})
                    </div>
                    <div v-else class="col-sm-4">
                      <div>
                        <label class="mt-3 mb-2">
                          Quantidade de números
                        </label>
                        <b-form-radio-group
                          v-model="fixedQuantity"
                          :options="fixedQuantityOptions"
                          class="mb-2"
                        ></b-form-radio-group>
                      </div>
                      <div class="mb-3" v-if="fixedQuantity === 'custom'">
                        <money3
                          id="rafflesquantity"
                          class="form-control"
                          :max="maxQuantity"
                          v-model="formRaffle.quantity"
                          v-bind="{
                            prefix: '',
                            precision: 0,
                            thousands: '.',
                            decimal: ','
                          }"
                        />
                        <div class="invalid-tooltip">
                          O sorteio deve ter entre 100 e {{ maxQuantity }} números
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-4">
                      <!-- <div class="mb-3">
                        <label class="form-label" for="rafflesreservedays"
                        >Dias para a reserva</label
                        >
                        <input
                            v-model="formRaffle.max_reserve_days"
                            id="rafflesreservedays"
                            name="rafflesreservedays"
                            type="text"
                            class="form-control"
                        />
                      </div> -->
                    </div>
                  </div>

                  <div class="card">
                    <div class="card-header">
                      <h4 class="card-title">
                        Configurações de controle do sorteio
                      </h4>
                    </div>
                    <div class="mb-3 row mt-3 ms-3">
                      <div class="col-md-6">
                        <b-form-checkbox
                          switch
                          id="website-ranking"
                          v-model="formRaffle.website_ranking"
                          name="website-ranking"
                          :value="3"
                          :unchecked-value="0"
                          :disabled="isFreeAccount()"
                        >
                          Exibir ranking de compradores no site
                        </b-form-checkbox>
                      </div>
                      <div class="col-md-6 pt-1">
                        &laquo; Exibe os 3 maiores compradores na página
                        do sorteio no site, ordenados pelo valor investido em ordem decrescente.
                      </div>
                    </div>

                    <div class="mb-3 row mt-3 ms-3">
                      <div class="col-md-6">
                        <b-form-checkbox
                          switch
                          id="show-on-home-page"
                          v-model="formRaffle.show_on_home_page"
                          name="show-on-home-page"
                        >
                          Mostrar sorteio na página inicial
                        </b-form-checkbox>
                      </div>
                      <div class="col-md-6 pt-1">
                        <div v-if="formRaffle.show_on_home_page">
                          &laquo; Desativar esta opção, faz com que o sorteio não fique visível na página inicial, sendo possível acessá-lo apenas com o link direto:
                        </div>
                        <div v-else class="text-info">
                          <button
                            v-if="formRaffle.slug"
                            type="button"
                            class="btn btn-outline-info waves-effect waves-light text-center btn-sm"
                            @click.prevent="copyContestLink(formRaffle)"
                            style="width: 100%;"
                          >
                            <i class="mdi mdi-content-copy"></i>
                            Copiar link deste sorteio
                          </button>
                          <span v-else>
                            Você poderá copiar o link direto após salvar o sorteio
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="mb-3 row">
                        <label for="max_order_numbers" class="col-md-4 col-form-label">
                          Quantidade mínima de números em um único pedido
                        </label>
                        <div class="col-md-3">
                          <input
                            v-model="formRaffle.min_order_numbers"
                            class="form-control text-center"
                            type="number"
                            id="min_order_numbers"
                            :max="formRaffle.max_order_numbers > 0 ? formRaffle.max_order_numbers : formRaffle.quantity"
                          >
                        </div>
                        <div class="col-md-5 pt-2">
                          &laquo; Deixe em branco ou com valor "0" caso não queira aplicar limites.
                        </div>
                      </div>
                      <div class="mb-3 row">
                        <label for="max_order_numbers" class="col-md-4 col-form-label">
                          Quantidade máxima de números em um único pedido
                        </label>
                        <div class="col-md-3">
                          <input
                            v-model="formRaffle.max_order_numbers"
                            class="form-control text-center"
                            type="number"
                            id="max_order_numbers"
                            :max="formRaffle.quantity > 5000 ? 5000 : formRaffle.quantity"
                          >
                        </div>
                        <div class="col-md-5 pt-2">
                          &laquo; Deixe em branco ou com valor "0" caso não queira aplicar limites.
                        </div>
                      </div>
                      <div class="mb-3 row">
                        <label for="max_pending_orders" class="col-md-4 col-form-label">
                          Quantidade máxima de pedidos pendentes por cliente
                        </label>
                        <div class="col-md-3">
                          <input
                            v-model="formRaffle.max_pending_orders"
                            class="form-control text-center"
                            type="number"
                            id="max_pending_orders"
                            min="0"
                          >
                        </div>
                        <div class="col-md-5 pt-2">
                          &laquo; Deixe em branco ou com valor "0" para não aplicar limites.
                        </div>
                      </div>
                      <div class="mb-3 row">
                        <label for="pending_lifetime" class="col-md-4 col-form-label">
                          Tempo até que um pedido pendente seja cancelado (minutos)
                        </label>
                        <div class="col-md-3">
                          <b-form-select
                            v-model="formRaffle.pending_lifetime"
                          >
                            <b-form-select-option
                              v-for="option in cancelPendingTimeOptions"
                              :key="option.value"
                              :value="option.value"
                            >
                              {{ option.text }}
                            </b-form-select-option>
                          </b-form-select>
                        </div>
                        <div class="col-md-5 pt-2">
                          &laquo; Selecione a opção "Não excluir" para desativar o recurso.
                        </div>
                      </div>
                      <!--<div class="mb-3 row mt-3">
                        <div class="col-md-4">
                          <div class="form-check form-switch form-switch-md mb-3">
                            <input
                              :disabled="lockControlCancelSorting"
                              v-model="formRaffle.keep_pending_latest"
                              class="form-check-input"
                              type="checkbox"
                              id="keep_pending_latest"
                            >
                            <label class="form-check-label" for="keep_pending_latest">
                              Cancelar pela ordem, pedidos mais antigos
                            </label>
                          </div>
                        </div>
                        <div class="col-md-8 pt-1">
                          &laquo; Desativar esta opção, faz com que os pedidos mais
                          recentes do cliente sejam cancelados durante a checagem
                        </div>
                      </div>-->
                    </div>
                  </div>

                  <hr>
                  <h4>Promoções</h4>
                  <p>Adicione promoções para impulsionar as vendas.</p>
                  <p><b>Como funciona?:</b> Vamos supor que você tenha um sorteio de 3000 números à R$ 5,00.
                    É possível criar uma promoção onde a partir de 100 números, cada um vai sair por R$ 2,50.</p>
                  <div class="col-lg-4">
                      <div class="row" v-for="(sale, index) in formRaffle.sales" :key="index">
                        <div class="col-lg-4">
                          <div class="mb-3">
                            <label>Quantidade</label>
                            <input
                                v-model="sale.quantity"
                                id="salesquantity"
                                name="salesquantity"
                                type="number"
                                class="form-control"
                            />
                          </div>
                        </div>
                        <div class="col-lg-4">
                          <div class="mb-3">
                            <label>R$ Valor</label>
                            <money3
                              id="rafflesprice"
                              class="form-control"
                              v-model="sale.price"
                              v-bind="{
                                prefix: '',
                                precision: 2,
                                thousands: '.',
                                decimal: ','
                              }"
                            />
                          </div>
                        </div>
                        <div class="col-lg-2" style="margin-top: 30px">
                          <span>
                            <div class="d-flex gap-3">
                              <a
                                  @click="removeSale(index)"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title=""
                                  class="text-danger"
                                  data-bs-original-title="Delete"
                                  aria-label="Delete"
                              >
                                <i class="mdi mdi-delete font-size-22"></i>
                              </a>
                            </div>
                          </span>
                        </div>
                      </div>
                    <button
                        :class="!showFormSales? 'btn btn-primary': 'btn btn-success'"
                        v-on:click="functionShowFormSales"
                        size="large"
                        type="button">
                        <i class="bx bx-plus me-1"></i>
                      Adicionar promoção
                    </button>
                  </div>
                  <hr>
                  <h4>Conta Bancária</h4>
                    <div class="col-md-6">
                      <div class="mb-3">
                        <select
                            id="bank_accounts"
                            v-model="formRaffle.bank_accounts"
                            class="form-control"
                            data-trigger
                            name="bank_accounts"
                            required
                        >
                          <option
                              v-for="account in bankAccountList"
                              :key="account.id"
                              :value="account.id"
                          >
                            {{ account.name }}</option>
                          <div class="invalid-tooltip">
                            Selecione pelo menos uma conta para receber
                          </div>
                        </select>
                      </div>
                    </div>
                  <hr>
                  <h4>Galeria de imagens</h4>
                  <p>Escolha as melhores fotos que representam o seu sorteio.</p>
                  <p><b>Como funciona?:</b> Após selecionar os números, o cliente é levado para a página do checkout. Caso você tenha configurado o pagamento automático, o cliente vai visualizar um QR Code para o pagamento.
                    Do contrário, vão ser exibidas as contas selecionadas abaixo onde o cliente deverá enviar o comprovante para a confirmação do pedido.</p>
                  <div class="row" style="margin-bottom: 10px;">
                    <div class="col-12">
                      <file-uploader
                        accepted="image/*"
                        :on="{
                          success: onUpload
                        }"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div
                      v-for="(image, index) in mappedImages"
                      class="col-lg-3"
                      :key="index"
                    >
                      <div>
                        <img
                          :src="image"
                          @click="showImage(index)"
                          style="max-width: 100%;"
                        />
                      </div>
                      <a
                        class="btn btn-danger waves-effect waves-light"
                        style="margin-top: -60px;"
                        @click="removeImage(index)"
                      >
                        <i class="mdi mdi-trash-can font-size-16"></i>
                      </a>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <vue-easy-lightbox
                        :visible="showGallery"
                        :imgs="mappedImages"
                        :index="imageIndex"
                        :zoomScale="0"
                        @hide="() => showGallery = false"
                      >
                        <template v-slot:toolbar="{}"></template>
                      </vue-easy-lightbox>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12 col-md-4 mt-3">
                      <button class="btn btn-block btn-primary" size="large" type="submit">
                        <i class="bx me-1" :class="formRaffle?.id ? 'bx-save' : 'bx-plus'"></i>
                        {{ formRaffle?.id ? 'Atualizar' : 'Cadastrar' }} sorteio
                      </button>
                    </div>
                    <div class="col-sm-12 col-md-8 mt-2 text-uppercase">
                      <div
                        class="alert alert-danger fade show"
                        role="alert"
                        v-if="isFreeAccount() && !contest?.id"
                      >
                        <i class="fas fa-info"></i>
                        Após cadastrar seu sorteio é necessário efetuar o pagamento da taxa de publicação.
                      </div>
                    </div>
                  </div>
                </form>
              </div>
          </div>
      </div>
    </div>
    <!-- end row -->
    <terms-of-use-modal />
  </Layout>
</template>
<script>
import Layout from "../../layouts/main"
import { mask } from 'vue-the-mask'
import { computed, onMounted, reactive, ref } from "vue"
import Notify from "../../../notify"
import { createRaffle, getRaffle, updateRaffle } from "./api"
import { getBankAccounts } from "../bank-account/api"
import { useRoute, useRouter } from "vue-router"
import TermsOfUseModal from "./terms-of-use-modal"
import FileUploader from '@/components/file-uploader'
import VueEasyLightbox from "vue-easy-lightbox"
import { debounce } from '@/utils'
import { contestPromitionsLimit, showUpgradeScreen, isFreeAccount, isLicensedAccount } from '@/feature-check'
import { useStore } from "vuex"
import { copyContestLink } from './utils'
import dayjs from 'dayjs'
import storage from '@/storage'
import { Toasts } from "../../../utils/toast-alerts"

const fixedQuantityOptions = [
  { text: '10 mil', value: 10000, disabled: false },
  { text: '100 mil', value: 100000, disabled: false },
  { text: '1 milhão', value: 1000000, disabled: isFreeAccount() },
  { text: 'personalizar', value: 'custom', disabled: false }
]

const cancelPendingTimeOptions = [
  { text: 'Não cancelar', value: 0},
  { text: '15 minutos', value: 15 },
  { text: '25 minutos', value: 25 },
  { text: '35 minutos', value: 35 },
  { text: '45 minutos', value: 45 },
  { text: '55 minutos', value: 55 },
]

export default {
  name: 'raffleForm',
  setup() {
    const store = useStore()
    const contest = ref(null) // new name
    const today = dayjs().format('YYYY-MM-DD')
    const user = storage.getItem('user')

    let date = new Date()
    const route = useRoute();
    const router = useRouter();
    const { id } = reactive(route.params);
    let disabledInput = ref(false)
    let bankAccountList = ref([])
    const showFormSales = ref(false)
    const showGallery = ref(false)
    const imageIndex = ref(0)
    const currentQuantity = ref(0)
    const contestUrl = ref('')
    const descriptionMaxLength = ref(600)
    const titleMaxLength = ref(70)
    const maxQuantity = ref(isFreeAccount() ? 100000 : 1000000)
    const fixedQuantity = ref(100000)

    const requirements = ref({
      bankAccounts: {
        loaded: false,
        accept: 0,
        message: `Você ainda não pode cadastrar sorteios,
          porque não possui contas de recebimento configuradas
          ou sua conta está aguardando liberação`
      }
    })

    const pendingRequirements = computed(() => {
      return Object.values(requirements.value).filter(({ loaded, accept }) => (loaded && !accept))
    })

    const moneyConfig = {
      prefix: 'R$',
      suffix: '',
      thousands: '.',
      decimal: ',',
      precision: 2,
    }

    const formRaffle = ref({
      title: "",
      short_description: "",
      start_date: today,
      price: 0.10,
      quantity: 10000,
      max_reserve_days: 1,
      whatsapp_number: user?.whatsapp,
      whatsapp_group: "",
      sales: [],
      bank_accounts: {},
      gallery: [],
      show_on_home_page: true,
      website_ranking: 0,
      min_order_numbers: 0,
      max_order_numbers: 0,
      max_pending_orders: 0,
      keep_pending_latest: true,
      pending_lifetime: 0,
    })

    const mappedImages = computed(() => {
      return formRaffle.value.gallery.map((i) => `${i}`)
    })

    onMounted(() => {
      fetchBankAccounts()
      if (id){
        getContestDetails(id)
        disabledInput.value = true
      }
    })

    async function getContestDetails(idRaffle) {
      try {
        const raffle = await getRaffle(Number(idRaffle));
        raffle.gallery = raffle.gallery.map(({ path }) => path)
        raffle.bank_accounts = raffle.bank_accounts[0].id
        formRaffle.value = raffle
        contest.value = raffle
        currentQuantity.value = raffle.quantity

        // normalize pending lifetime
        const currentPendingOption = cancelPendingTimeOptions.find((o) => {
          return o.value === contest.value.pending_lifetime
        })
        if (!currentPendingOption) {
          for (const option of cancelPendingTimeOptions) {
            if (option.value > contest.value.pending_lifetime) {
              formRaffle.value.pending_lifetime = option.value
              break
            }
          }
        }
      } catch (error) {
        Toasts('error', 'Falha ao carregar os sorteio')
      }
    }

    async function submitRaffle(){
      try {
        if (fixedQuantity.value !== 'custom' && !formRaffle.value.id) {
          formRaffle.value.quantity = parseInt(fixedQuantity.value)
        }

        formRaffle.value.bank_accounts = [ formRaffle.value.bank_accounts ]

        if (id) {
          await updateRaffle(id, formRaffle.value )
        } else {
          await createRaffle(formRaffle.value)
        }

        const notifyTitle = id
          ? 'Sorteio atualizado com sucesso!'
          : 'Sorteio cadastrado com sucesso!'

        let notifyText = null
        if (isFreeAccount()) {
          if (id && contest.value?.status === 'PAYMENT') {
            notifyText = 'Seu pedido está pendente e aguardando a confirmação de pagamento'
          } else {
            notifyText = 'Seu pedido ficará pendente até a confirmação de pagamento'
          }
        }

        Notify.create({
          position: 'top-end',
          icon: 'success',
          title: notifyTitle,
          text: notifyText,
          showConfirmButton: false,
          timer: 1500,
          willClose() {
            router.push({
              name: 'raffles-list'
            })
          }
        })
      } catch (e) {
        const errors = e?.response?.data ?? {}
        const text = Object.values(errors)[0] ?? 'Falha ao salvar sorteio'
        Toasts('error', text)
      }
    }

    async function fetchBankAccounts() {
      try {
        const response = await getBankAccounts({
            'exists[account_verified_at]': true
        });

        bankAccountList.value = response.data;

        requirements.value.bankAccounts.accept = bankAccountList.value.length > 0
        requirements.value.bankAccounts.loaded = true

        /**
         * @todo
         * Define automaticamente a primeira conta bancária ativa
         * no sorteio, quando este ainda não possui uma conta vinculada.
         */
        if (
          formRaffle.value.bank_accounts.length === 0 &&
          bankAccountList.value.length > 0
        ) {
          const activeBankAccount = bankAccountList.value.find(({ main }) => main)
          if (activeBankAccount) {
            formRaffle.value.bank_accounts.push(activeBankAccount.id)
          }
        }

      } catch (error) {
        Toasts('error', 'Falha ao carregar as contas bancárias')
      }
    }

    function functionShowFormSales() {
      if (formRaffle.value.sales.length >= contestPromitionsLimit()) {
        showUpgradeScreen()
        return
      }

      formRaffle.value.sales.push({
        quantity: 1,
        price: "0,10"
      })
    }

    function removeSale(index) {
        formRaffle.value.sales.splice(index,1)
    }

    const onUpload = (file) => {
      const images = JSON.parse(file.xhr.responseText)
      const paths = images.map(({ cdn_url }) => cdn_url)
      for (const path of paths) {
        if (!formRaffle.value.gallery.includes(path)) {
          formRaffle.value.gallery.push(path)
        }

      }
    }

    const showImage = (index) => {
      imageIndex.value = index
      showGallery.value = true
    }

    const removeImage = (index) => {
      formRaffle.value.gallery.splice(index, 1)
    }

    const lockControlCancelSorting = computed(() => {
      const maxPendingUnset = parseInt(formRaffle.value.max_pending_orders) === 0
      const lifetimeUnset = parseInt(formRaffle.value.pending_lifetime) === 0
      return maxPendingUnset || lifetimeUnset
    })

    return {
      pendingRequirements,
      formRaffle,
      lockControlCancelSorting,
      functionShowFormSales,
      removeSale,
      submitRaffle,
      fetchBankAccounts,
      bankAccountList,
      date,
      getContestDetails,
      disabledInput,
      showFormSales,
      onUpload,
      showGallery,
      imageIndex,
      showImage,
      mappedImages,
      removeImage,
      moneyConfig,
      fixedQuantity,
      fixedQuantityOptions,
      cancelPendingTimeOptions,
      currentQuantity,
      maxQuantity,
      contestUrl,
      descriptionMaxLength,
      debounce,
      contest,
      isFreeAccount,
      showPaymentRequest() {
        store.dispatch('app/setContestPaymentRequest', contest.value?.payment)
      },
      titleMaxLength,
      copyContestLink,
      isLicensedAccount
    }
  },
  components: {
    Layout,
    FileUploader,
    VueEasyLightbox,
    TermsOfUseModal,
  },
  directives: {
    mask,
  }
};

</script>

<style scoped>
  .remove-paddings {
    --dp-input-padding: 0px 0px 0px 0px;
    --dp-input-icon-padding: 35px;
  }
</style>